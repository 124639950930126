@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

html {
	scroll-behavior: smooth;
}

#aboutus,
#services,
#contactus {
	scroll-margin-top: 80px; /* height of your AppBar */
}

body {
	font-size: 62.5%;
	overflow-x: hidden;
	margin: 0;
	scroll-behavior: smooth;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
